import { mainTheme } from '@indly/themes/main.theme';

export const globalStyles = {
	html: {
		height: '100%',
		overflow: 'hidden',
	},
	body: {
		height: '100%',
		overflow: 'hidden',
		margin: 0,
	},
	'body > div:first-child': {
		height: '100%',
		overflow: 'hidden',
	},
	'div#__next': {
		height: '100%',
		overflow: 'hidden',
	},
	'div#__next > div': {
		height: '100%',
		overflow: 'hidden',
	},
	'@keyframes building-blocks': {
		'0%, 20%': {
			opacity: 0,
			transform: 'translateY(-300%)',
		},
		'30%, 70%': {
			opacity: 1,
			transform: 'translateY(0)',
		},
		'90%, 100%': {
			opacity: 0,
			transform: 'translateY(300%)',
		},
	},
	'.daterange-picker': {
		display: 'table',
	},
	'.MuiPopover-root .MuiBackdrop-root': {
		backgroundColor: 'transparent !important',
	},
	'.simplebar-scrollbar::before': {
		backgroundColor: mainTheme.palette.purple.main,
	},
	'.MuiTooltip-popper': {
		whiteSpace: 'pre-line',
	},
};
