import { createTheme } from "@mui/material/styles";
import { colorPalette } from "./color-palette.theme";
export const mainTheme = createTheme({
    palette: colorPalette,
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    padding: 0,
                    color: colorPalette.white.contrastText,
                    backgroundColor: colorPalette.white.main
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(32, 0, 28, 0.1)"
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    backgroundColor: colorPalette.white.main,
                    color: colorPalette.black.main,
                    borderTopLeftRadius: "20px"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: "initial"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "initial",
                    boxShadow: "none"
                },
                text: {
                    color: colorPalette.black.main
                },
                contained: {
                    "&:hover": {
                        boxShadow: "none"
                    }
                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    boxShadow: "none"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "initial"
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    ".Mui-disabled": {
                        cursor: "not-allowed"
                    }
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    ".MuiButton-root:hover, .MuiIconButton-root:hover": {
                        backgroundColor: colorPalette.greyish.light
                    }
                }
            }
        }
    },
    shape: {
        pageCurl: "20px",
        smallRadius: "5px",
        sidebarToggleButtonSize: "30px",
        mainSidebarWidth: "86px",
        mobileMenuHeight: "56px",
        loginSidebarWidth: "30%",
        sidebarWidth: 500,
        newSidebarWidth: 700,
        filterBarWidth: 350,
        animationDelay: 225,
        headerBarHeight: 48,
        clientSubHeaderBarHeight: 76,
        jurisdictionSubHeaderBarHeight: 90,
        headerButtonHeight: 36
    }
});
