var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"904bbe0266c9002df8ec8bc8b7c46738a0a00eed"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init as sentryInit } from '@sentry/nextjs';
import * as Sentry from '@sentry/react';

sentryInit({
	dsn: 'https://efc4c05ab7254adcb4d18e4f7d45e1ce@o53500.ingest.sentry.io/6055406',

	enabled: true,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	integrations: [
		new Sentry.Replay({
			maskAllText: false,
			maskAllInputs: false,
			blockAllMedia: false,
		}),
	],

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1,

	replaysOnErrorSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	denyUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
	],

	ignoreErrors: [/^ResizeObserver loop completed with undelivered notifications.$/],
});
