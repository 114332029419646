import { mainTheme } from '@indly/themes/main.theme';
import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)`
	height: 20px;
	position: absolute;
	width: 20px;

	&::after {
		animation: building-blocks 2.1s ease infinite backwards;
		background: ${mainTheme.palette.white.main};
		content: '';
		display: block;
		height: 20px;
		width: 20px;
	}
`;

export const MainLoading = () => (
	<Box
		sx={{
			position: 'absolute',
			display: 'flex',
			height: '100%',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: mainTheme.palette.blue.light,
			zIndex: 1,
		}}
	>
		<StyledBox
			sx={{
				transform: 'translate(-50%, -50%) translate(60%, 120%)',
				'&::after': {
					animationDelay: '0.3s',
				},
			}}
		/>
		<StyledBox
			sx={{
				transform: 'translate(-50%, -50%) translate(-60%, 120%)',
				'&::after': {
					animationDelay: '0.45s',
				},
			}}
		/>
		<StyledBox
			sx={{
				transform: 'translate(-50%, -50%) translate(120%, 0)',
				'&::after': {
					animationDelay: '0.6s',
				},
			}}
		/>
		<StyledBox
			sx={{
				transform: 'translate(-50%, -50%)',
				'&::after': {
					animationDelay: '0.75s',
				},
			}}
		/>
		<StyledBox
			sx={{
				transform: 'translate(-50%, -50%) translate(-120%, 0)',
				'&::after': {
					animationDelay: '0.9s',
				},
			}}
		/>
		<StyledBox
			sx={{
				transform: 'translate(-50%, -50%) translate(60%, -120%)',
				'&::after': {
					animationDelay: '1.05s',
				},
			}}
		/>
		<StyledBox
			sx={{
				transform: 'translate(-50%, -50%) translate(-60%, -120%)',
				'&::after': {
					animationDelay: '0.3s',
				},
			}}
		/>
	</Box>
);
