import dayjs, { type Dayjs } from 'dayjs';
import { AccessTokenExpirationType } from '../../dictionaries/access-token-expiration-types';
import { AdministratorSortList } from '../../dictionaries/administrator-sort-list';
import { AutomationSortList } from '../../dictionaries/automation-sort-list';
import {
	BusinessStructure,
	BusinessStructureSchemaKey,
} from '../../dictionaries/business-structures';
import { ClientSortList } from '../../dictionaries/client-sort-list';
import { Country } from '../../dictionaries/countries';
import { Currency } from '../../dictionaries/currencies';
import { DataZone } from '../../dictionaries/data-zones';
import { DateFieldFormat, DateFormat } from '../../dictionaries/date-formats';
import { DefaultManagedCompanyType } from '../../dictionaries/default-managed-company-types';
import { DepartmentSortList } from '../../dictionaries/department-sort-list';
import { EmailTemplateSortList } from '../../dictionaries/email-template-sort-list';
import { EngagementDeterminationStatus } from '../../dictionaries/engagement-determination-statuses';
import { EngagementTemplateSortList } from '../../dictionaries/engagement-template-sort-list';
import { FactorSortList } from '../../dictionaries/factor-sort-list';
import { FieldStateList } from '../../dictionaries/field-state-list';
import { FileSortList } from '../../dictionaries/file-sort-list';
import { FileType } from '../../dictionaries/file-types';
import { ImportStatus } from '../../dictionaries/import-statuses';
import { InsurancePolicyType } from '../../dictionaries/insurance-policy-types';
import { JurisdictionCosts } from '../../dictionaries/jurisdiction-costs';
import { JurisdictionSortList } from '../../dictionaries/jurisdiction-sort-list';
import { JurisdictionTimelines } from '../../dictionaries/jurisdiction-timelines';
import { SystemLocale, SystemLocaleSchemaKey } from '../../dictionaries/languages';
import { LocationType } from '../../dictionaries/location-types';
import { PartnerType } from '../../dictionaries/partner-types';
import { PayRateUnit, PayRateUnitSchemaKey } from '../../dictionaries/pay-rate-units';
import { PhoneType } from '../../dictionaries/phone-types';
import { ProjectSortList } from '../../dictionaries/project-sort-list';
import { QueryFunctionDuration } from '../../dictionaries/query-function-durations';
import { RecordStatus } from '../../dictionaries/record-statuses';
import { RiskWithRecommendationStatus } from '../../dictionaries/risk-with-recommendation-statuses';
import { RoleSortList } from '../../dictionaries/role-sort-list';
import { SkillType } from '../../dictionaries/skill-types';
import { SmsTemplatesSortList } from '../../dictionaries/sms-templates-sort-list';
import { SourceSortList } from '../../dictionaries/source-sort-list';
import { StageSortList } from '../../dictionaries/stage-sort-list';
import { SurveyQuestionSortList } from '../../dictionaries/survey-question-sort-list';
import { TagSortList } from '../../dictionaries/tag-sort-list';
import { TeamSortList } from '../../dictionaries/team-sort-list';
import { TestSortList } from '../../dictionaries/test-sort-list';
import { TimeFormat } from '../../dictionaries/time-formats';
import { Timezone } from '../../dictionaries/timezones';
import { SortList } from '../../dictionaries/types/dictionaries.type';
import { WorkerSortList } from '../../dictionaries/worker-sort-list';
import { GlobalConfigurationType } from '../../types/global-configuration.type';
import { DbDocumentsMap } from '../database/documents-map.database';

export const SYSTEM_BILLING_BEGIN_DATE: Dayjs = dayjs().year(2023).startOf('year');

export const GlobalConfig: GlobalConfigurationType = {
	settings: {
		address: {
			type: LocationType.HOME,
			city: 'San Francisco',
			state: 'CA',
			country: Country.US,
		},
		currency: Currency.USD,
		dataZone: DataZone.US,
		dateFormat: DateFormat.MM_DD_YYYY,
		dateFieldFormat: DateFieldFormat.MM_DD_YYYY,
		file: {
			maxFileSizeLabel: '10 MB',
			maxFileSizeBytes: 10_485_760,
			maxTotalFileSizeLabel: '50 MB',
			maxTotalFileSizeBytes: 52_428_800,
		},
		locale: SystemLocale.EN,
		payRate: {
			unit: PayRateUnit.BIWEEKLY,
		},
		timeFormat: TimeFormat.TWELVE_HOUR,
		timezone: Timezone.AMERICA_TORONTO,
		phoneType: PhoneType.CELL,
	},
	defaults: {
		accessToken: {
			expirationType: AccessTokenExpirationType.THIRTY_DAYS,
		},
		address: {
			type: LocationType.OTHER,
			country: Country.US,
			state: 'CA',
		},
		administrator: {
			sort: AdministratorSortList.NAME,
		},
		automation: {
			status: RecordStatus.DRAFT,
			settings: {
				timezone: Timezone.AMERICA_TORONTO,
			},
			sort: AutomationSortList.NAME,
		},
		businessRegistration: {
			structure: BusinessStructure.CORPORATION,
			fileType: FileType.ARTICLES_OF_INCORPORATION,
		},
		client: {
			compliance: {
				defaultEngagementRiskWithRecommendationDueDateOffset: 259200, // 3 days
			},
			features: {
				isSourcesEnabled: true,
				isStagesEnabled: true,
				isProjectsEnabled: true,
				isTeamsEnabled: true,
				isDepartmentsEnabled: true,
			},
			privacy: {
				isHiringManagerCompliancePrivate: false,
				isHiringManagerDocumentPrivate: false,
			},
			mapping: {
				defaultManagedCompanyType: DefaultManagedCompanyType.OPTIONAL_VENDOR,
			},
			sort: ClientSortList.NAME,
		},
		company: {
			configurations: {
				engagements: {
					template: FieldStateList.EDITABLE,
					externalId: FieldStateList.EDITABLE,
					hiringManager: FieldStateList.EDITABLE,
					role: FieldStateList.EDITABLE,
					source: FieldStateList.EDITABLE,
					stage: FieldStateList.EDITABLE,
					department: FieldStateList.EDITABLE,
					team: FieldStateList.EDITABLE,
				},
				payRateUnits: {
					[PayRateUnitSchemaKey.HOURLY]: true,
					[PayRateUnitSchemaKey.HALF_DAY_FOUR_HOUR]: false,
					[PayRateUnitSchemaKey.HALF_DAY_FIVE_HOUR]: false,
					[PayRateUnitSchemaKey.EIGHT_HOUR]: false,
					[PayRateUnitSchemaKey.TEN_HOUR]: false,
					[PayRateUnitSchemaKey.TWELVE_HOUR]: false,
					[PayRateUnitSchemaKey.DAILY]: true,
					[PayRateUnitSchemaKey.WEEKLY]: true,
					[PayRateUnitSchemaKey.BIWEEKLY]: true,
					[PayRateUnitSchemaKey.SEMI_MONTHLY]: true,
					[PayRateUnitSchemaKey.MONTHLY]: true,
					[PayRateUnitSchemaKey.FIXED_CONTRACT]: true,
				},
			},
			settings: {
				enabledLanguages: {
					[SystemLocaleSchemaKey.EN]: {
						isEnabled: true,
					},
					[SystemLocaleSchemaKey.FR_CA]: {
						isEnabled: false,
					},
				},
				defaultWorkerRole: DbDocumentsMap.roles.systemWorkerRole,
				defaultHiringManagerRole: DbDocumentsMap.roles.systemHiringManagerRole,
				defaultAdministratorRole: DbDocumentsMap.roles.systemAdministratorRole,
				isEmailEditableForWorker: false,
				isSendEngagementClassificationReportEmailToWorker: true,
				isSendEngagementClassificationReportEmailToHiringManager: true,
				isSendEngagementClassificationReportEmailToCompany: false,
			},
			whitelabelSettings: {
				isEnabled: false,
			},
		},
		dateFormat: DateFormat.MM_DD_YYYY,
		department: {
			sort: DepartmentSortList.NAME,
		},
		engagement: {
			_assignedComplianceManager:
				DbDocumentsMap.users.systemIndependentlyDefaultComplianceManager,
			classification: {
				risksWithRecommendations: {
					_status: RiskWithRecommendationStatus.MISSING,
				},
			},
			queryFunctionDuration: QueryFunctionDuration.THREE_DAYS,
			sort: SortList.DATE_CREATED,
		},
		engagementTemplate: {
			sort: EngagementTemplateSortList.TITLE,
		},
		emailTemplate: {
			status: RecordStatus.DRAFT,
			sort: EmailTemplateSortList.NAME,
		},
		factor: {
			status: RecordStatus.DRAFT,
			sort: FactorSortList.LABEL,
		},
		file: {
			type: FileType.OTHER_FILE_TYPE,
			sort: FileSortList.NAME,
		},
		jurisdiction: {
			status: RecordStatus.DRAFT,
			compliance: {
				minimumNumberOfInitialSurveyQuestions: 15,
				minimumNumberOfMonthlyCheckInQuestions: 15,
			},
			additionalProcessing: {
				isRequired: false,
				estimatedTimeline: JurisdictionTimelines.LESS_THAN_ONE_WEEKS,
				estimatedCost: JurisdictionCosts.FIVE_HUNDRED,
			},
			documents: {
				businessRegistration: {
					isEnabled: true,
					enabledBusinessStructures: {
						[BusinessStructureSchemaKey.SOLE_PROPRIETORSHIP]: true,
						[BusinessStructureSchemaKey.GENERAL_PARTNERSHIP]: true,
						[BusinessStructureSchemaKey.LIMITED_PARTNERSHIP]: true,
						[BusinessStructureSchemaKey.LLC]: true,
						[BusinessStructureSchemaKey.CORPORATION]: true,
					},
				},
				generalLiabilityInsurancePolicy: {
					isEnabled: true,
				},
				taxPayerStatus: {
					isEnabled: false,
				},
				taxRegistration: {
					isEnabled: true,
				},
				workersCompensationInsurancePolicy: {
					isEnabled: true,
				},
				otherInsurancePolicy: {
					isEnabled: true,
				},
			},
			actionItem: {
				isEnabled: true,
				isMappedToSurveyQuestion: false,
			},
			sort: JurisdictionSortList.COUNTRY,
		},
		language: {
			isEnabled: true,
		},
		locale: SystemLocale.EN,
		partner: {
			type: PartnerType.REFERRER,
		},
		payRate: {
			rate: 0,
			unit: PayRateUnit.BIWEEKLY,
			currency: Currency.USD,
		},
		phone: {
			type: PhoneType.OTHER,
		},
		project: {
			sort: ProjectSortList.NAME,
		},
		import: {
			status: ImportStatus.DRAFT,
			document: {
				status: ImportStatus.DRAFT,
			},
		},
		insurancePolicy: {
			fileType: FileType.CERTIFICATE_OF_INSURANCE,
			policyType: InsurancePolicyType.WORKERS_COMPENSATION,
		},
		role: {
			sort: RoleSortList.LABEL,
		},
		skill: {
			type: SkillType.SKILL,
		},
		smsTemplate: {
			status: RecordStatus.DRAFT,
			sort: SmsTemplatesSortList.NAME,
		},
		source: {
			sort: SourceSortList.NAME,
		},
		stage: {
			sort: StageSortList.NAME,
		},
		surveyQuestion: {
			status: RecordStatus.DRAFT,
			sort: SurveyQuestionSortList.LABEL,
			isRequired: false,
			isKnockout: false,
		},
		taxRegistration: {
			fileType: FileType.VAT_SALES_REGISTRATION,
		},
		team: {
			sort: TeamSortList.NAME,
		},
		test: {
			status: RecordStatus.DRAFT,
			sort: TestSortList.LABEL,
		},
		timeFormat: TimeFormat.TWELVE_HOUR,
		timezone: Timezone.AMERICA_TORONTO,
		userDefinedTag: {
			sort: TagSortList.LABEL,
		},
		worker: {
			sort: WorkerSortList.NAME,
		},
	},
	gridDefaultParams: {
		limit: 25,
	},
	system: {
		emails: {
			engagementDeterminationReport: {
				worker: {
					template: 'emtem_2Qt2uUYXaNAMpRP2iMfVFKzQoh4',
				},
				hiringManager: {
					template: 'emtem_2SEcA7Nfee6NVxcDGk92GUz2k7m',
				},
				company: {
					template: 'emtem_2SpW0O686KjxDy81AwCRf0Q9zx0',
				},
			},
			noReply: {
				name: 'Independently.ai',
				address: 'no-reply@independently.ai',
			},
			sme: {
				name: 'Independently SME Team',
				address: 'sme-team@independently.ai',
			},
			internalEngagementNotifications: {
				name: 'Independently.ai Internal Engagement Notifications',
				address: 'internal-engagement-notifications@independently.ai',
			},
		},
		engagements: {
			dueDateOffsetBeforeStartDate: 86_400, // (in seconds) 24 hours
		},
		filestorage: {
			ObjectLockRention: 315_360_000, // (in seconds) 10 years
		},
		allowedCountries: [Country.US, Country.CA],
	},
};
